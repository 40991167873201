import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {  createSubCategories, fetchCategories, uploadCategoryImage } from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { CiSaveDown2 } from "react-icons/ci";
import { languageOptions } from "../../utils/utils";
import { Input } from 'antd';

function CreateSubCtegory() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    titleEn: "",
    titleSecondary: "",
    descriptionEn: "",
    descriptionSecondary: "",
    language: "",
    image: null,
  });
  const { TextArea } = Input;
  const [errors, setErrors] = useState({
    titleEn: '',
    titleSecondary: '',
    descriptionEn: "",
    descriptionSecondary: "",
    language: "",
    image: "",
  });
  const [previewImage, setPreviewImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {

      const previewURL = URL.createObjectURL(file);
      setPreviewImage(previewURL);


      const imagePath = await uploadCategoryImage(file);
      setFormData((prevData) => ({
        ...prevData,
        image: imagePath,
      }));

      toast.success("Image uploaded successfully!");
    } catch (error) {
      toast.error("Failed to upload image.");
      console.error("Error:", error);
    }
  };
   const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      language: '',
      titleEn: '',
      titleSecondary: '',
      descriptionEn: "",
      descriptionSecondary: "",
    });

    let isValid = true;
    if (!formData.language) {
      setErrors((prevErrors) => ({ ...prevErrors, language: 'Language is required' }));
      isValid = false;
    }

    if (!formData.titleEn) {
      setErrors((prevErrors) => ({ ...prevErrors, titleEn: 'name (English) is required' }));
      isValid = false;
    }

    if (!formData.titleSecondary) {
      setErrors((prevErrors) => ({ ...prevErrors, titleSecondary: `name (${languageOptions[formData.language]}) is required` }));
      isValid = false;
    }

    if (!formData.descriptionEn) {
      setErrors((prevErrors) => ({ ...prevErrors, descriptionEn: 'Description (English) is required' }));
      isValid = false;
    }

    if (!formData.descriptionSecondary) {
      setErrors((prevErrors) => ({ ...prevErrors, descriptionSecondary: `Description (${languageOptions[formData.language]}) is required` }));
      isValid = false;
    }
    if (!isValid) return;

    const apiData = {
      name: {
        en: formData.titleEn,
        secondary: formData.titleSecondary,
      },
      discription: {
        en: formData.descriptionEn,
        secondary: formData.descriptionSecondary,
      },
      language: {
        secondary: formData.language,
      },
      category: formData.category, 
      subCategoryImage: formData.image
    };

    const response = await createSubCategories(apiData);
    console.log(response, "this is response");
    if (response) {
      toast.success(response.message || "Sub category created successfully");
      navigate('/AllSubCategory');
      setFormData({
        titleEn: "",
        titleSecondary: "",
        descriptionEn: "",
        descriptionSecondary: "",
        language: "",
        image: null
      });
      setPreviewImage(null);
    }
  };

  const getCategories = async () => {
    try {
      const fetchedCategories = await fetchCategories();
      if (fetchedCategories?.length === 0) {
      } else {
        setCategories(fetchedCategories.categories);
      }
    } catch (err) {
      console.error("Error in getCategories:", err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <CommonLayout>
      <div className="container my-3">
        <div className="card shadow-lg p-4">
          <h3 className="text-center mb-4 text-primary fw-bold">Add New Sub-Category</h3>
          <form >
            <div className="mb-3">
              <label className="form-label fw-bold">Language</label>
              <select
                className="form-select "
                name="language"
                value={formData.language}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Language ---
                </option>
                {Object.entries(languageOptions).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>{errors.language && <div className="text-danger">{errors.language}</div>}
            </div>

            <div className="col-md-12 mb-3">
                <label className="form-label fw-bold"> Category</label>
                <select
                  className="form-select"
                  value={formData.category}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                >
                  <option value="">select category</option>
                  {categories?.length > 0 ? (
                    categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {typeof category.name === "object"
                          ? category.name.en
                          : category.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No categories available</option>
                  )}
                </select>
              
              </div>

            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label fw-bold">Name (English)</label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleEn"
                  value={formData.titleEn}
                  onChange={handleChange}
                  placeholder="Enter title in English"
                  required
                />
                {errors.titleEn && <div className="text-danger">{errors.titleEn}</div>}
              </div>

              {/* <div className="mb-3"> */}
              <div className="col-md-6">
                <label className="form-label fw-bold">
                  Name ({languageOptions[formData.language]})
                </label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleSecondary"
                  value={formData.titleSecondary}
                  onChange={handleChange}
                  placeholder={`Enter title in ${languageOptions[formData.language]
                    }`}
                  required
                />
                {errors.titleSecondary && <div className="text-danger">{errors.titleSecondary}</div>}
              </div>
            </div>

         <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label fw-bold">
                  Description (English)
                </label>
                <TextArea
                  className="form-control formclass"
                  name="descriptionEn"
                  value={formData.descriptionEn}
                  onChange={handleChange}
                  placeholder="Enter description in English"
                  required
     
                />
                {errors.descriptionEn && <div className="text-danger">{errors.descriptionEn}</div>}
              </div>

              <div className="mb-4 col-md-6">
                <label className="form-label fw-bold">
                  Description ({languageOptions[formData.language]})
                </label>
                <TextArea
                  className="form-control formclass"
                  name="descriptionSecondary"
                  value={formData.descriptionSecondary}
                  onChange={handleChange}
                  placeholder={`Enter description in ${languageOptions[formData.language]
                    }`}
                  required
                />
                {errors.descriptionSecondary && <div className="text-danger">{errors.descriptionSecondary}</div>}
              </div>
              </div>

              <div className="">
              <label className="form-label fw-bold">Upload Image</label>
              <input
                type="file"
                className="form-control formclass"
                onChange={handleImageChange}
                accept="image/*"
                required
              />
              {previewImage && (
                <div className="mt-3">
                  <img src={previewImage} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                </div>
              )}
            </div>


            <div className='text-end mt-4'>
              <Button
                icon={<CiSaveDown2 />}
                className='mybtn'
                onClick={handleSubmit}

              >
                Submit
              </Button>
            </div>

          </form>
        </div>
      </div>
    </CommonLayout>
  );
}

export default CreateSubCtegory;



import { Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';

import ResetPassword from './pages/Auth/ResetPassword';
import ChangePassword from './pages/profile/changePassword/ChangePassword';
import SignUp from './pages/Auth/SignUp';
import Home from './pages/Home/Home';
import ContentManager from './pages/contentManager.js/ContentManager';
import ForgetPassword from './pages/Auth/ForgetPassword';
import CreateCategories from './pages/Categories/CreateCategories';
import CreateSecondCategory from './pages/Categories/CreateSecondCategory';
import AllCategories from './pages/Categories/AllCategories';
import AllManagers from './pages/contentManager.js/AllManagers';
import Profile from './pages/profile/Profile/Profile';
import CreateTag from './pages/tag/CreateTag';
import AllTag from './pages/allTag/AllTag';
import AllArticle from './pages/ArticalForm/AllArticle';
import MoreArticleDetail from './pages/ArticalForm/MoreArticleDetail';
import AllBanner from './pages/Banner/AllBanner';
import CreateBanner from './pages/Banner/CreateBanner';

import AllSubCategory from './pages/SubCategory/AllSubCategory';
import CreateSubCtegory from './pages/SubCategory/CreateSubCtegory';
import EditArticleNew from './pages/ArticalForm/EditArticleNew';
import Dashboard from './pages/Home/Dashboard';
import EnArticleForm from './pages/ArticalForm/EnArticleForm';
import SecondaryArticleForm from './pages/ArticalForm/SecondaryArticleForm';
import EnEditArticleForm from './pages/ArticalForm/EnEditArticleForm';

function App() {
  return (
    <div className="App">


  <Routes>
    {/* Auth */}
    <Route path='/' element = {  <Login/>} />
    <Route path='/Signup' element = {  <SignUp/>} />
    <Route path='/ForgetPassword' element = {  <ForgetPassword/>} />
    <Route path='/ResetPassword/:token' element = {  <ResetPassword/>} />

    {/* home */}
    <Route path='/Dashboard' element = {  <Dashboard/>} />
    <Route path='/changePassword' element = {  <ChangePassword/>} />
    <Route path='/Profile' element = {  <Profile/>} />
    <Route path='/ContentManager' element = {  <ContentManager/>} />
    <Route path='/AllManager' element = {  <AllManagers/>} />

    <Route path='/SecondaryArticleForm' element = {  <SecondaryArticleForm/>} />
    <Route path='/EnglishArticleForm' element = {  <EnArticleForm/>} />
    <Route path='/articleDetail/:id/:langCode' element = {  <MoreArticleDetail/>} />
    <Route path='/edit-article/:id/:langCode' element = {  <EditArticleNew/>} />
    <Route path='/English-edit-article/:id/:langCode' element = {  <EnEditArticleForm/>} />

    <Route path='/CreateCategories' element = {  <CreateCategories/>} />
    <Route path='/CreateSecondCategory' element = {  <CreateSecondCategory/>} />
    <Route path='/AllCategories' element = {  <AllCategories/>} />
    <Route path='/CreateTag' element = {  <CreateTag/>} />
    <Route path = "/AllTag" element = {  <AllTag/>} />
    <Route path = "/AllBanner" element = {  <AllBanner/>} />
    <Route path = "/CreateBanner" element = {  <CreateBanner/>} />
    
    <Route path = "/AllSubCategory" element = {  <AllSubCategory/>} />
    <Route path = "/CreateSubCategory" element = {  <CreateSubCtegory/>} />
  
  </Routes>
    </div>
  );
}

export default App;


import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { Input, Modal, Button , Image} from "antd";
import {
  deleteSubCategory,
  fetchCategories,
  fetchSubCategories,
  updateSubCategory,
  uploadCategoryImage,
} from "../../services/Apis/ApiServices";
import DeleteModal from "../../Component/DeleteModal";
import { toast } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import { languageOptions } from "../../utils/utils";
import { GrFormPreviousLink ,GrFormNextLink  } from "react-icons/gr";

function AllSubCategory() {
  const [searchTerm, setSearchTerm] = useState("");
  const [ currentPage , setCurrentPage ] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [Subcategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [SubcategoryToEdit, setSubCategoryToEdit] = useState(null);
  const [categories,setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [file,setFile] = useState(null);
  const [category, setCategory] = useState([]);

  const {TextArea} = Input;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getAllSubCategories = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchSubCategories(page);
      if (response) {
        setSubCategories(response.subCategories);
        setTotalPages(response.totalPages);
      } else {
        setSubCategories([]);
      }
    } catch (error) {
      console.error("Error fetching sub categories:", error);
      setSubCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllSubCategories(currentPage);
  }, [currentPage]);

  const filteredCategories = (Subcategories || []).filter((category) => {
    const search = searchTerm.toLowerCase();
    return (
      category.name.en.toLowerCase().includes(search) ||
      category.name.secondary.toLowerCase().includes(search)
    );
  });

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleShowMore = (category) => {
    setSelectedSubCategory(category);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedSubCategory(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedSubCategory(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteSubCategory(selectedSubCategory._id);

      if (response?.success) {
        toast.success(response.message);
        getAllSubCategories();
        setShowDeleteModal(false);
      } else {
        toast.error(response?.message || "Failed to delete sub category.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the sub category.");
      console.error("Delete error:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedSubCategory(null);
  };

  const handleEditClick = (category) => {
    setSubCategoryToEdit(category);
    setIsEditModalVisible(true);
  };

  

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
    setSubCategoryToEdit(null);
  };


  const getAllCategories = async () => {
    try {
      setLoading(true);
      const response = await fetchCategories();

      if (response) {
        setCategories(response.categories);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getAllCategories();
  },[setIsEditModalVisible])
  

  const handleSaveEdit = async () => {

    try {
      let imagePath = SubcategoryToEdit.categoryImage;

      if (file) {
        try {
          const response = await uploadCategoryImage(file);

          if (response) {
             imagePath = response;
            setSubCategoryToEdit((prev) => ({
              ...prev,
              subCategoryImage: imagePath,
            }));
          } else {
            console.error("Image upload failed: No path returned from API");
          }
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
      const apiData = {
        _id: SubcategoryToEdit?._id,
        name:{
          en: SubcategoryToEdit?.name?.en,
          secondary: SubcategoryToEdit?.name?.secondary,
        },
        discription: {
          en: SubcategoryToEdit?.discription?.en,
          secondary: SubcategoryToEdit?.discription?.secondary,
        },
        language: {
          secondary: SubcategoryToEdit?.language?.secondary,
        },
        category: SubcategoryToEdit?.category?._id,
        subCategoryImage: imagePath,
      };
        
      const response = await updateSubCategory(apiData);
      if (response?.success) {
        toast.success("Sub category updated successfully!");
        getAllSubCategories();
        setImagePreview(null);
        setFile(null);
        handleEditModalClose();
      } else {
        toast.error("Failed to update sub category. Please try again.");
      }
    } catch (error) {
      console.error("Error updating sub category:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

    const handleEditChange = (e) => {
      const { name, value } = e.target;

      setSubCategoryToEdit((prevTag) => {
        if (name === "language") {
          return {
            ...prevTag,
            language: {
              ...prevTag.language,
              secondary: value,
            },
          };
        } else if (name === "titleEn") {
          return {
            ...prevTag,
            name: {
              ...prevTag.name,
              en: value,
            },
          };
        } else if (name === "titleSecondary") {
          return {
            ...prevTag,
            name: {
              ...prevTag.name,
              secondary: value,
            },
          };
        } else if (name === "descriptionEn") {
          return {
            ...prevTag,
            discription: {
              ...prevTag.discription,
              en: value,
            },
          };
        } else if (name === "descriptionSecondary") {
          return {
            ...prevTag,
            discription: {
              ...prevTag.discription,
              secondary: value,
            },
          };
        }
        else if(name === 'category'){
          return {
            ...prevTag,
            category:{
              _id:value
            }
          }
        }
        return prevTag;
      });
    };
    const handleImageChange = async (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const previewUrl = URL.createObjectURL(selectedFile);
        setImagePreview(previewUrl);
        setFile(selectedFile); // Store the file in state
      }
    };
    

  return (
    <CommonLayout>
      <div className="d-flex align-items-center justify-content-end mb-5">
       
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb justify-content-end"
            style={{
              background: "transparent",
              padding: "0",
              margin: "0",
              fontSize: "14px",
            }}
          >
            <li className="breadcrumb-item">
              <Link>
                <MdHome size={20} color="#4B49AC" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                style={{
                  textDecoration: "none",
                  color: "#333",
                  fontWeight: "600",
                }}
              >
                All sub Categories
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="container card rounded shadow-lg p-4">
      <div className="d-flex justify-content-between mb-4">
          <h4 className=" fw-bold middleclass">All Article</h4>
          <Input
          prefix={<FaSearch />}
          placeholder="Search by title"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            maxWidth: "300px",
            borderRadius: "50px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        />

        </div>
      {loading ? (
  <div className="text-center">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
) : (
  <table className="table table-bordered">
    <thead className="bgtable">
      <tr>
        <th >Serial No</th>
        <th>Image</th>
        <th>Sub Category Name</th>
        <th>Language</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {filteredCategories?.length > 0 ? (
        filteredCategories?.map((category, index) => (
          <tr key={category?._id}>
            <td className="pclass">{index + 1}</td>
            <td>
                  <Image
                    src={category.subCategoryImage}
                    alt='No image'
                    className="img-fluid avatar rounded-circle"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                  />
                </td>
            <td  className="pclass">{category?.name?.en}</td>
            <td  className="pclass">
              {category?.language?.primary} / {category?.language?.secondary}
            </td>
            <td  className="pclass">
              <button
                type="button"
                className="btn btn-primary"
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => handleShowMore(category)}
              >
               <FaEye color="black" size={18} />
              </button>
              <button
                type="button"
                className="btn btn-danger"
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => handleDeleteClick(category)}
              >
               <FaTrashAlt color="red" size={18} />
              </button>
              <button
                type="button"
                className="btn btn-warning"
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => handleEditClick(category)}
              >
                <MdEdit color="green" size={18} />
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", fontWeight: "bold" }}>
            No sub Categories found
          </td>
        </tr>
      )}
    </tbody>
  </table>
)}
 <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <button
            className="btn btn-outline-primary prevbtn"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
             <GrFormPreviousLink size={20} />
          </button>
          <span className="fw-bold">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary nextbtn"
          >
              <GrFormNextLink size={20} />
          </button>
        </div>

</div>

      <Modal
        // title="Edit Category"
        visible={isEditModalVisible}
        onCancel={handleEditModalClose}
        footer={null}
        style={{ borderRadius: "15px" }}
      >
        {SubcategoryToEdit && (
          <div>
            <div className="row mb-3">
              <label
                htmlFor="categoryName"
                className="form-label fw-bold"
                style={{ textAlign: "left" }}
              >
                Name (English)
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control formclass"
                  id="categoryName"
                  name="titleEn"
                  value={SubcategoryToEdit?.name?.en}
                  onChange={handleEditChange}
                />
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control formclass"
                  id="categoryName"
                  name="titleSecondary"
                  value={SubcategoryToEdit?.name?.secondary}
                  onChange={handleEditChange}
                />
              </div>
            </div>

            <label
              htmlFor="categoryDescription"
              className="form-label fw-bold"
              style={{ textAlign: "left" }}
            >
              Description
            </label>
            <div className="mb-3">
            <TextArea
          className="form-control mb-3"
          id="categoryDescription"
          name="descriptionEn"
          value={SubcategoryToEdit?.discription?.en || ""}
          onChange={handleEditChange}
        />
        <TextArea
          className="form-control"
          id="categoryDescription"
          name="descriptionSecondary"
          value={SubcategoryToEdit?.discription?.secondary || ""}
          onChange={handleEditChange}
        />
            </div>

            <div className="mb-3">
              <label htmlFor="category" className="form-label fw-bold">
                Category
              </label>
              <select
                id="category"
                className="form-select"
                name="category"
                value={SubcategoryToEdit?.category || ""}
                onChange={handleEditChange}
              >
                <option value="" disabled>
                  Select category
                </option>
                {categories?.length > 0 ? (
                  categories?.map((cat) => (
                    <option key={cat?._id} value={cat?._id}>
                      {typeof cat?.name === "object" ? cat?.name?.en : cat?.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No sub category available</option>
                )}
              </select>
            </div>


            <div className="mb-3">
              <label htmlFor="categoryLanguage" className="form-label fw-bold">
                Language
              </label>
              
              <select
                className="form-select"
                id="categoryLanguage"
                name="language"
                value={SubcategoryToEdit?.language?.secondary}
                onChange={handleEditChange}
              >
                {Object.entries(languageOptions).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label
                htmlFor="categoryImageUpload"
                className="form-label fw-bold"
              >
                Upload sub Category Image
              </label>
              <input
                type="file"
                className="form-control formclass"
                id="categoryImageUpload"
                onChange={handleImageChange}
              />
            </div>

            <div className="mb-3">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Category Preview"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "100px" }}
                />
              ) : (
                <p></p>
              )}
            </div>

            <div
              className="mb-3"
              style={{ border: "1px solid #ccc", padding: "10px" }}
            >
              {SubcategoryToEdit.subCategoryImage ? (
                <img
             
                  src={SubcategoryToEdit.subCategoryImage}
                  alt="sub Category"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>

            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={handleSaveEdit}
              >
                Save Changes
              </button>
            </div>
          </div>
        )}
      </Modal>

      <div>
        <DeleteModal
          isVisible={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      </div>

      {selectedSubCategory && (
        <Modal
          title={
            <h4
              style={{
                fontWeight: "bold",
                margin: "0",
                textAlign: "center",
                marginBottom: "35px",
              }}
            >
              {selectedSubCategory?.name?.en} / {selectedSubCategory?.name?.secondary}
            </h4>
          }
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          style={{ borderRadius: "15px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold" }}> Description</h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedSubCategory?.discription?.en || "No description available"}
              </p>
            </div>

            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold", marginBottom: "23px" }}> </h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedSubCategory?.discription?.secondary ||
                  "No description available"}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </CommonLayout>
  );
}

export default AllSubCategory;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function SignUp() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        if (!username) newErrors.username = 'Username is required';
        if (!email) newErrors.email = 'Email is required';
        if (!password) newErrors.password = 'Password is required';
        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Registering with:', { username, email, password });
            setUsername('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            toast.success('Successfully Registered');
            navigate('/login'); // Navigate to login after successful signup
        }
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0" style={{
                    backgroundImage: "url('https://img.freepik.com/free-photo/flowers-notebook-near-laptop_23-2147759307.jpg?semt=ais_hybrid')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                }}>
                    <div className="row w-100 mx-0 my-5">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left rounded px-sm-5">
                                <div className="brand-logo d-flex align-items-center justify-content-center">
                                    <img src="https://img.freepik.com/premium-vector/blog-word-drawn-lettering-typographic-element_205544-12246.jpg?semt=ais_hybrid" alt="logo" style={{ height: "70px" }} />
                                </div>
                                <h4>Create Your Account</h4>
                                <h6 className="font-weight-light">Fill in the details to sign up.</h6>
                                <form className="pt-1" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control form-control-lg ${errors.username ? 'is-invalid' : ''}`}
                                            placeholder="Username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className={`form-control form-control-lg ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className={`form-control form-control-lg ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            className={`form-control form-control-lg ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                    </div>
                                    <div className="mt-3 d-grid gap-2">
                                        <button type="submit" className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN UP</button>
                                    </div>
                                    <div className="text-center mt-4 font-weight-light">
                                        Already have an account? <Link to='/' className="text-primary">Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;

import React, { useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { toast } from "react-toastify";
import {
  AddContentManager,
  uploadProfileImage,
} from "../../services/Apis/ApiServices";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button} from 'antd';

import { CiSaveDown2 } from "react-icons/ci";
function ContentManager() {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    permissions: {
      manageUsers: false,
      manageArticles: false,
      manageTags: false,
      manageCategories: false,
      manageBanners: false,
    },
    avatar: null,
  });
  const [profile, setProfile] = useState(null);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();

  // Helper function for handling input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: "" }); 
  };

  // Handle permission checkbox changes
  const handlePermissionChange = (e) => {
    const { id, checked } = e.target;
  
    // Update permissions in formData
    const updatedPermissions = {
      ...formData.permissions,
      [id]: checked,
    };
  
    setFormData({
      ...formData,
      permissions: updatedPermissions,
    });
  
    // Manage validation dynamically
    if (Object.values(updatedPermissions).some((value) => value)) {
      // Remove the permissions error if at least one is selected
      setErrors((prevErrors) => {
        const { permissions, ...rest } = prevErrors;
        return rest;
      });
    } else {
      // Add the permissions error if none are selected
      setErrors((prevErrors) => ({
        ...prevErrors,
        permissions: "At least one permission must be selected",
      }));
    }
  };
  

  // Handle profile image file changes
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfile(file);
    setPreview(URL.createObjectURL(file)); // Preview image
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
  
    // Simple validation checks
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.password.trim()) newErrors.password = "Password is required";
  
    // Validate if at least one permission is selected
    const { manageArticles, manageTags, manageCategories, manageBanners } = formData.permissions;
    if (!manageArticles && !manageTags && !manageCategories && !manageBanners) {
      newErrors.permissions = "At least one permission must be selected";
    }
  
    setErrors(newErrors); // Save errors in state
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  
  // Submit form handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const newErrors = validateForm(); // Run validation
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors); // Set error messages
      return; // Stop form submission if validation fails
    }

    try {
      let avatarPath = "";
      if (profile) {
        avatarPath = await uploadProfileImage(profile); // Upload image if present

        console.log("avatarPath", avatarPath);
      }

      // Merge avatar path if available into formData
      const finalData = { ...formData, avatar: avatarPath };

      // Send data to the API to create content manager
      const response = await AddContentManager(finalData);

      if (response.success) {
        toast.success("Content Manager created successfully");
        navigate("/AllManager");
      } else {
        toast.error(response.error || response.message || "An error occurred");
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
      
      if (error.response) {
        toast.error(error.response.data.message || "Request failed");
      } else if (error.request) {
        toast.error("No response received, please try again");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <CommonLayout>
      <div className="container ">
        <div
          className="card shadow-sm p-4 col-md-12"
        >
          <h3 className="text-center mb-4 text-primary fw-bold">
            Create Content Manager
          </h3>
          <form>
            {/* Name Field */}
            <div className="mb-4">
              <label htmlFor="name" className="form-label fw-bold">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control formclass shadow-sm"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <div className="text-danger small">{errors.name}</div>
              )}
            </div>

            {/* Email Field */}
            <div className="mb-4 row">
              <div className="col-md-6">
              <label htmlFor="email" className="form-label fw-bold">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control shadow-sm formclass"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-danger small">{errors.email}</div>
              )}
            </div>

            {/* Password Field */}
            <div className=" col-md-6">
              <label htmlFor="password" className="form-label fw-bold">
                Password
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="form-control shadow-sm formclass"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.password && (
                <div className="text-danger small">{errors.password}</div>
              )}
            </div>
            </div>

            {/* Profile Picture Upload */}
            <div className="mb-4">
              <label htmlFor="profilePicture" className="form-label fw-bold">
                Profile Picture
              </label>
              <input
                type="file"
                id="profilePicture"
                className="form-control shadow-sm formclass"
                accept="image/*"
                onChange={handleFileChange}
              />
              {preview && (
                <div className="text-center mt-3">
                  <img
                    src={preview}
                    alt="Profile Preview"
                    className="rounded-circle border"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </div>

            {/* Permissions Section */}
            <h5 className="mt-2 text-dark fw-bold">Permissions</h5>
            <div className=" p-1 d-flex" style={{gap:"30px"}}>
              {Object.entries(formData.permissions).map(([key, value]) => (
                <div className="spaceclass" key={key}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={key}
                    checked={value}
                    onChange={handlePermissionChange}
                  />
                  <label className="form-check-label" htmlFor={key}>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                  </label>
                </div>
              ))}
             
            </div>
            {errors.permissions && (
    <div className="text-danger small">{errors.permissions}</div> 
  )}

            {/* Submit Button */}
            <div className='text-end mt-4'>
                    <Button
                        icon={<CiSaveDown2 />}
                        className='mybtn'
                        onClick={handleSubmit}
                     
                    >
                    Submit
                    </Button>
                </div>
           
          </form>
        </div>
      </div>
    </CommonLayout>
  );
}

export default ContentManager;

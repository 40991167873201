import React, { useState , useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

function CommonLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); 
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false); 
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true); 
      } else {
        setIsSidebarOpen(false); 
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  return (
    <div>
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <div className="layout-container" style={{ position: "relative" }}>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          onCloseSidebar={handleCloseSidebar}
        />
         <div
          className={`main-content ${
            isSidebarOpen ? "content-overlay" : ""
          }`}
          style={{
            backgroundColor: "rgb(240, 242, 245)",
            padding: "24px",
          }}
        >
          <div>{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CommonLayout;
